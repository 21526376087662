/**
 * @generated SignedSource<<ccdb89ef4c150debd5bc359f06f8add8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MemberGroupVisibility = "admin_only" | "everyone" | "%future added value";
export type CreateSubGroupFormQuery$variables = {
  memberGroupId: string;
  membershipIds?: ReadonlyArray<string> | null;
};
export type CreateSubGroupFormQuery$data = {
  readonly node: {
    readonly __typename: "MemberGroup";
    readonly id: string;
    readonly memberGroupMemberships: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly userId: string;
        };
      }>;
    };
    readonly organizationId: string;
    readonly productId: string | null;
    readonly visibility: MemberGroupVisibility;
    readonly " $fragmentSpreads": FragmentRefs<"MemberGroupTagFragment">;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type CreateSubGroupFormQuery = {
  response: CreateSubGroupFormQuery$data;
  variables: CreateSubGroupFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "memberGroupId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "membershipIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "memberGroupId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "productId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "membershipIds",
      "variableName": "membershipIds"
    }
  ],
  "concreteType": "MemberGroupMembershipNodeConnection",
  "kind": "LinkedField",
  "name": "memberGroupMemberships",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroupMembershipNodeEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupMembership",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "userId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSubGroupFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MemberGroupTagFragment"
              }
            ],
            "type": "MemberGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSubGroupFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "color",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isSystem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "MemberGroup",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c1cdce90091b8d42e84d5d36e9a8db5",
    "id": null,
    "metadata": {},
    "name": "CreateSubGroupFormQuery",
    "operationKind": "query",
    "text": "query CreateSubGroupFormQuery(\n  $memberGroupId: ID!\n  $membershipIds: [ID!]\n) {\n  node(id: $memberGroupId) {\n    __typename\n    ... on MemberGroup {\n      id\n      __typename\n      organizationId\n      productId\n      visibility\n      memberGroupMemberships(membershipIds: $membershipIds) {\n        edges {\n          node {\n            id\n            userId\n          }\n        }\n      }\n      ...MemberGroupTagFragment\n    }\n    id\n  }\n}\n\nfragment MemberGroupTagFragment on MemberGroup {\n  id\n  name\n  kind\n  color\n  role\n  isSystem\n  product {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb67e8bff6b617d7554379e93098dc76";

export default node;
