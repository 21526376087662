import { useLabel } from "@/core/context/LabelsContext"
import { useFormStore } from "@/core/form/store/FormStore"
import { CreateMemberGroupFormState } from "@/product/common/member-group/common/create/form/CreateMemberGroupForm"
import { CreateSubGroupFormMutation } from "@/product/common/member-group/common/create/form/__generated__/CreateSubGroupFormMutation.graphql"
import { CreateSubGroupFormQuery } from "@/product/common/member-group/common/create/form/__generated__/CreateSubGroupFormQuery.graphql"
import MemberGroupFormFields, {
  MemberGroupFormFieldsSkeleton,
} from "@/product/common/member-group/common/MemberGroupFormFields"
import MemberGroupTag from "@/product/common/member-group/common/tag/MemberGroupTag"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import Form from "@components/form/Form"
import { displaySuccessToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoText } from "@disco-ui"
import { observer } from "mobx-react-lite"
import { ConnectionHandler, graphql, useLazyLoadQuery } from "react-relay"

interface CreateSubGroupFormProps {
  memberGroupId: GlobalID
  selectedMembershipIds?: GlobalID[]
  onCancel: () => void
  onSave: () => void
}

function CreateSubGroupForm({
  memberGroupId,
  selectedMembershipIds = [],
  onCancel,
  onSave,
}: CreateSubGroupFormProps) {
  const { node } = useLazyLoadQuery<CreateSubGroupFormQuery>(
    graphql`
      query CreateSubGroupFormQuery($memberGroupId: ID!, $membershipIds: [ID!]) {
        node(id: $memberGroupId) {
          ... on MemberGroup {
            id
            __typename
            organizationId
            productId
            visibility
            memberGroupMemberships(membershipIds: $membershipIds) {
              edges {
                node {
                  id
                  userId
                }
              }
            }
            ...MemberGroupTagFragment
          }
        }
      }
    `,
    {
      memberGroupId,
      membershipIds: selectedMembershipIds,
    },
    { fetchPolicy: "network-only" }
  )
  const memberGroup = Relay.narrowNodeType(node, "MemberGroup")
  const memberGroupMemberships = Relay.connectionToArray(
    memberGroup?.memberGroupMemberships
  )

  const form = useFormStore<CreateSubGroupFormMutation, CreateMemberGroupFormState>(
    graphql`
      mutation CreateSubGroupFormMutation(
        $input: CreateMemberGroupInput!
        $connections: [ID!]!
      ) {
        response: createMemberGroup(input: $input) {
          node
            @prependNode(connections: $connections, edgeTypeName: "MemberGroupNodeEdge") {
            id
            ...MemberGroupsListFragment
            parentMemberGroup {
              ...AdminGroupsListRowFragment
            }
            product {
              ...ExperienceSettingsGroupsTabFragment
            }
            memberGroupMemberships {
              edges {
                node {
                  id
                  memberGroup {
                    ...MemberGroupTagFragment
                  }
                  productMembership {
                    ...useMemberGroupTagsList_ProductMembershipFragment
                  }
                  organizationMembership {
                    ...useMemberGroupTagsList_OrganizationMembershipFragment
                  }
                }
              }
            }
            ...GroupsListChildGroupRowFragment
          }
          errors {
            field
            message
          }
        }
      }
    `,
    {
      organizationId: memberGroup?.organizationId || "",
      name: "",
      color: "#FFF5D6",
      selectedUserIds: memberGroupMemberships.map((mgm) => mgm.userId),
      memberGroupId: memberGroup?.id || "",
      visibility: memberGroup?.visibility,
    }
  )

  const membersLabel = useLabel(
    memberGroup?.productId ? "product_member" : "organization_member"
  )
  if (!memberGroup) return null

  const tag = (
    <DiscoText>
      {`Add ${membersLabel.plural} from: `}
      <MemberGroupTag memberGroupKey={memberGroup} />
    </DiscoText>
  )

  return (
    <Form
      testid={"CreateSubGroupForm"}
      onSubmit={handleSubmit}
      buttons={
        <>
          <DiscoButton color={"grey"} variant={"outlined"} onClick={onCancel}>
            {"Cancel"}
          </DiscoButton>
          <Form.SubmitButton
            form={form}
            testid={"CreateMemberGroupForm.submit"}
            disabled={!form.isChanged || form.isSubmitting}
          >
            {"Create Sub-Group"}
          </Form.SubmitButton>
        </>
      }
    >
      <MemberGroupFormFields form={form} tableLabel={tag} isSubGroup />
    </Form>
  )

  async function handleSubmit() {
    const { didSave } = await form.submit(
      {
        organizationId: memberGroup!.organizationId,
        productId: memberGroup!.productId,
        userIds: form.state.selectedUserIds,
        color: form.state.color,
        name: form.state.name,
        parentMemberGroupId: form.state.memberGroupId,
        kind: "custom",
      },
      {
        connections: [
          ConnectionHandler.getConnectionID(
            memberGroup!.id,
            "AdminGroupsListRow__childrenGroups"
          ),
        ],
      }
    )

    if (!didSave) return

    displaySuccessToast({
      message: "Sub-group created!",
      testid: "CreateSubGroupForm.success-toast",
    })
    onSave()
  }
}

export default Relay.withSkeleton<CreateSubGroupFormProps>({
  component: observer(CreateSubGroupForm),
  skeleton: MemberGroupFormFieldsSkeleton,
})
